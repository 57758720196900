<template>
  <div id="amend">
    <div class="amend" :style="{width: width + 'px'}">
      <div class="close" @click="close">
        <img class="close_img" src="../../assets/img/close.png" alt="" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "amend",
  props: {
    width: {
      type: String,
      default: '620'
    }
  },
  data() {
    return {};
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit("btnClose");
    },
  },
};
</script>

<style lang="less" scoped>
#amend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  .amend {
    padding: 20px 30px;
    width: 620px;
    min-width: 620px;
    background: var(--white);
    position: relative;
    color: var(--black);
    .close {
      cursor: pointer;
      .close_img {
        position: absolute;
        right: 6px;
        top: 6px;
        width: 34px;
        height: 34px;
      }
    }
  }
}
</style>
